<template>
  <span class="regular-12 text-black text-start">
    <v-select
      :options="
        countries.filter((e) => value.all.indexOf(e.code.toUpperCase()) > -1)
      "
      v-model="value.active"
      label="name"
      multiple
      :placeholder="$t(`deliveryCountriesSetting.addANewCountry`)"
      :reduce="(c) => c.id"
    />
  </span>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "DeliveryCountriesSetting",
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: String,
  },
  data() {
    return {
      store: useStore(),
      show: false,
      value: this.modelValue,
    };
  },
  computed: {
    countries() {
      return this.store.state.countries;
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.$emit("update:modelValue", this.value);
        this.$emit("change");
      },
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
